/* DEBUG STYLES */
/* .safe-zone {
  outline: solid yellow;
}
.safe-zone > div {
  outline: solid red;
}
.safe-zone > div > div {
  outline: solid blue;
}  */

/* CUSTOM STYLES */
*,
*::before,
*::after {
  box-sizing: border-box;
}

#root {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-background {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.safe-zone {
  height: calc(100% - 3em);
  width: calc(100% - 3em);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* TINY SLIDER STYLES */
/* HET STORIES VAK */
.safe-zone > div {
  max-width: 55%;
  display: flex;
  justify-content: center;
  position: relative;
  left: 1%;
  bottom: -2%;
}

.safe-zone > div > div {
  max-width: 100%;
}

.position-relative {
  position: relative;
}

.text-white {
  color: white;
}

.tns-inner {
  /* background:#9b59b6; */
  overflow: hidden;
}

.tns-item > video,
.tns-item > img {
  border-radius: 2vh;
}

@keyframes dropIn {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

.drop-in {
  animation: dropIn 3s ease forwards;
}

.video-placeholder {
  height: 20em;
  width: 100%;
  background-color: tomato;
  display: grid;
  place-content: center;
}

/* ANIMATIONS */
.my-node {
  color: white;
}

/* appear */
.my-node-appear {
  opacity: 0;
}
.my-node-appear.my-node-appear-active {
  opacity: 1;
  transition: opacity 5000ms;
}

/* enter */
.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 5000ms;
}

/* exit */
.my-node-exit {
  opacity: 1;
}
.my-node-exit.my-node-exit-active {
  opacity: 0;
  transition: opacity 5000ms;
}
.my-node-exit-done {
  opacity: 0;
}
